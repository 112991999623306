@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);
    box-shadow: 0 0 0 0 rgba(74, 255, 215, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

body {
  scrollbar-color: #363563 #272747;
}

.pulse {
  animation: pulse 2s infinite;
}

.active-point {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #4affd7;
  border-radius: 20px;
}

.stub {
  width: 40px;
}
